import { createApp } from 'vue';
import App from './App.vue';

import router from '@/app/router';
import store from '@/app/store';

const app = createApp( App );
app.use( router );
app.use( store );


if( window._rootData.entry )
{
    store.dispatch( 'entry/merge', window._rootData.entry );
}
if( window._rootData.competition )
{
    store.dispatch( 'competition/import', window._rootData.competition );
}

app.mount( '#app' );
