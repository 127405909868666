
export default {
    namespaced: true,

    state: {
    },

    getters: {
        match: state => state.match,
        opponents: state => state.opponents
    },

    mutations: {
        initCompetitionData: ( state, payload ) =>
        {
            state.match = payload.match;
            state.opponents = payload.opponents;
        }
    },

    actions: {
        import: ( context, payload ) =>
        {
            context.commit( 'initCompetitionData', payload );
        }
    }
};
