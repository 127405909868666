import Proxy from './Proxy';

class EntryProxy extends Proxy
{
    constructor( parameters = {})
    {
        super( 'api/entries', parameters );
    }

    auth( item )
    {
        return this.submit( 'post', `/${this.endpoint}/auth`, item );
    }
}

export default EntryProxy;
