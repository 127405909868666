<template>
    <svg id="header-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1767 565">
        <g id="EY">
            <polyline id="E" points="156.98 286.96 69.51 286.96 69.51 325.04 148.06 325.04 148.06 373.3 69.51 373.3 69.51 410.97 178.43 410.97 178.43 464.02 4.06 464.02 4.06 234.52 127.75 234.67" fill="#fff"/>
            <polygon id="Y" points="224.46 464.02 224.46 373.3 148.06 234.52 220.96 234.52 257.87 308.48 294.85 234.52 366.26 234.52 289.47 373.3 289.47 464.02 224.46 464.02" fill="#fff"/>
        </g>
        <g id="PADEL">
            <path id="P" d="M951.24,306.85c0,12.52-1.98,23.23-5.93,32.14-3.96,8.91-9.41,16.22-16.37,21.93-6.96,5.71-15.2,9.91-24.71,12.62-9.51,2.7-19.82,4.05-30.94,4.05h-47.31v76.3h-43.1V238.52h94.17c10.11,0,19.68,1.4,28.69,4.21,9.01,2.8,16.9,7.06,23.65,12.77,6.76,5.71,12.09,12.84,16,21.4,3.9,8.56,5.86,18.55,5.86,29.96Zm-44.31,.9c0-4.41-.65-8.34-1.95-11.79-1.3-3.45-3.38-6.33-6.23-8.64-2.85-2.3-6.23-4.03-10.14-5.18-3.9-1.15-8.26-1.73-13.07-1.73h-49.56v55.42h50.16c10.01,0,17.65-2.33,22.9-6.98,5.26-4.66,7.88-11.69,7.88-21.1Z" fill="#fff"/>
            <path id="A" d="M1160,453.89h-47.31l-16.97-47.91h-78.7l-16.97,47.91h-46.11l82.45-215.37h41.75l81.85,215.37Zm-79-88.91l-24.63-70.14-24.63,70.14h49.26Z" fill="#fff"/>
            <path id="D" d="M1374.47,346.2c0,12.62-1.43,24.03-4.28,34.24-2.85,10.21-6.83,19.33-11.94,27.33-5.11,8.01-11.14,14.89-18.1,20.65-6.96,5.76-14.52,10.54-22.68,14.34-8.16,3.81-16.77,6.61-25.83,8.41-9.06,1.8-18.2,2.7-27.41,2.7h-64.28V238.52h67.43c15.32,0,29.54,1.93,42.65,5.78,13.12,3.86,24.43,10.04,33.94,18.55,9.51,8.51,16.97,19.62,22.38,33.34,5.41,13.72,8.11,30.39,8.11,50.01Zm-44.31,0c0-11.01-1.23-20.62-3.68-28.84-2.46-8.21-6.26-15.07-11.41-20.58-5.16-5.5-11.79-9.61-19.9-12.32s-17.87-4.05-29.29-4.05h-22.83v131.56h21.18c11.31,0,21.1-1.58,29.36-4.73,8.26-3.15,15.09-7.61,20.5-13.37,5.41-5.76,9.44-12.69,12.09-20.8,2.65-8.11,3.98-17.07,3.98-26.88Z" fill="#fff"/>
            <path id="E-2" d="M1571.52,453.89h-154.09V238.52h149.59v42.2h-106.48v37.25h86.51v41.75h-86.51v51.96h110.99v42.2Z" fill="#fff"/>
            <path id="L" d="M1617.33,453.89V238.52h43.1v172.87h107.99v42.5h-151.09Z" fill="#fff"/>
        </g>
        <polygon id="stripe" points="430.83 85.04 0 165.65 0 165.65 430.83 0 430.83 85.04" fill="#fbe74d"/>
        <path d="M571.21,465c1,0-1,0,0,0,25.57-27.81,52.96-55.08,77.77-83.55,45.89-52.64,58.53-126.69-7.56-147.45-3.2-1.08-12.67-1.32-19,0-22.7,5.22-38.62,19.5-51.21,39.12-12.59-19.62-28.51-33.9-51.21-39.12-6.33-1.32-15.8-1.08-19,0-66.09,20.76-53.45,94.8-7.56,147.45,24.81,28.47,52.21,55.74,77.77,83.55,1,0-1,0,0,0" fill="#fae64e"/>
        <path class="pulse" d="M571.21,465c1,0-1,0,0,0,25.57-27.81,52.96-55.08,77.77-83.55,45.89-52.64,58.53-126.69-7.56-147.45-3.2-1.08-12.67-1.32-19,0-22.7,5.22-38.62,19.5-51.21,39.12-12.59-19.62-28.51-33.9-51.21-39.12-6.33-1.32-15.8-1.08-19,0-66.09,20.76-53.45,94.8-7.56,147.45,24.81,28.47,52.21,55.74,77.77,83.55,1,0-1,0,0,0" fill="#fae64e"/>
    </svg>
</template>

<style lang="scss" scoped>
    @keyframes pulse-anim-auto {
        0% { transform: scale(1); }
        5% { transform: scale(1.07); }
        10% { transform: scale(1); }
        15% { transform: scale(1.07); }
        20% { transform: scale(1); }
    }

    .pulse {
        transform-origin: 32% 62%;
        animation: pulse-anim-auto 2s infinite;
    }
</style>