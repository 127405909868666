<template>
    <div class="main-layout">
        <header>
            <img class="ey-logo" src="/static/images/ey-logo.png" />
        </header>

        <div class="content">
            <div class="bg">
                <img src="/static/images/header-image.jpg" />
                <div class="padelbal grey right"></div>
                <div class="padelbal grey left"></div>
            </div>

            <ey-header-logo class="ey-loves-padel"></ey-header-logo>

            <div class="panel">
                <div class="stripe topleft"></div>
                <div class="padelbal topleft"></div>
                <div class="stripe bottomright"></div>
                <div class="padelbal bottomright"></div>

                <div class="hashtag">
                    <a>#EYnlSponsorPadel</a>
                </div>

                <slot></slot>
            </div>
        </div>

        <ey-footer></ey-footer>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    import Footer from '@/app/components/Footer';
    import EyHeaderLogo from '@/app/components/EyHeaderLogo';

    export default {
        components: {
            'ey-footer': Footer,
            'ey-header-logo': EyHeaderLogo
        },

        computed: {
            activeStep: function()
            {
                switch( this.$route.name )
                {
                    case 'landing': return 1;
                    case 'register': return 2;
                    case 'confirm': return 3;
                    default: return 0;
                }
            }
        }
    };
</script>
