<template>
    <div id="main">
        <ynk-layout>
            <router-view v-slot="{ Component, route }">
                <transition mode="out-in"
                            @enter="getAnimateIn"
                            @leave="getAnimateOut"
                            :css="false"
                            appear>

                    <component :is="Component" :key="route.name" ref="currentView"/>
                </transition>
            </router-view>
        </ynk-layout>
    </div>
</template>

<style lang="scss">
    @import './static/scss/app.scss';
</style>

<script>
    import { gsap } from '@/vendor';
    import Main from '@/app/layouts/Main';

    export default {
        name: 'App',

        components: {
            'ynk-layout': Main
        },

        watch: {
            '$route': function()
            {
                setTimeout( () =>
                {
                    this.oldView = this.$refs.currentView;
                }, 500 );
            }
        },

        mounted()
        {
            this.panelVisible = false;
            window.addEventListener( 'resize', this.onResize );
            this.onResize();
        },

        methods: {
            onResize()
            {
                document.documentElement.style.setProperty( '--vh100', window.innerHeight + 'px' ); // fix for 100vh on iphones
            },

            getAnimateIn( el, done )
            {
                let tl = gsap.timeline({ onComplete: done });

                if( this.$route.path === '/' && this.panelVisible === false )
                {
                    this.panelVisible = true;
                    tl.from( '.panel', { opacity: 0, y: 20 }, 0 );
                }
                else
                {
                    this.panelVisible = true;
                }

                if( this.$refs.currentView && typeof this.$refs.currentView.getAnimateIn === 'function' )
                {
                    tl.add( this.$refs.currentView.getAnimateIn(), 0 );
                }
                else
                {
                    tl.to({ foo: 0 }, { foo: 1, duration: 0.01 });
                }
            },

            getAnimateOut( el, done )
            {
                let tl = gsap.timeline({ onComplete: done });

                if( this.oldView && typeof this.oldView.getAnimateOut === 'function' )
                {
                    tl.add( this.oldView.getAnimateOut(), 0 );
                }
                else
                {
                    tl.to({ foo: 0 }, { foo: 1, duration: 0.01 });
                }
            }
        }
    };
</script>
