import { createStore } from 'vuex';

import entry from './modules/entry';
import competition from './modules/competition';

export default createStore({

    modules: {
        entry,
        competition
    },

    state: {

    },

    getters: {

    },

    mutations: {

    },

    actions: {

    }
});
