<template>
    <footer>
        <div class="flex-row align-start">
            <img class="combinatielogo" src="/static/images/combinatielogo-knltb-ey.png" />
            <div class="links">
                <a href="https://sites.ey.com/sites/EY-Padel-Sponsorship-Netherlands" target="_blank">Questions?</a>
            </div>
        </div>

        <div class="flex-row align-end">
            <p>EY refers to the global organization, and may refer to one or more, of the member firms of Ernst & Young Global Limited, each of which is a separate legal entity. Ernst & Young Global Limited, a UK company limited by guarantee, does not provide services to clients.</p>
            <div class="social-links">
                <a href="https://www.facebook.com/eynederland/" target="_blank" class="circle"><img class="fb" src="/static/images/facebook.png" /></a>
                <a href="https://www.linkedin.com/company/1073" target="_blank" class="circle"><img class="li" src="/static/images/linkedin.png" /></a>
                <a href="https://twitter.com/ey_nederland" target="_blank" class="circle"><img class="tw" src="/static/images/twitter.png" /></a>
                <a href="https://www.youtube.com/channel/UCibo5ikz3OqpdNZpHul8TlA" target="_blank" class="circle"><img class="yt" src="/static/images/youtube.png" /></a>
            </div>
        </div>
    </footer>
</template>