import EntryProxy from '@/app/proxies/EntryProxy';

export default {
    namespaced: true,

    state: {
        entry: {
            name: '',
            email: '',
            branch: '',
            department: '',
            level: '-',
            match_type: '-',
            teammate: '',
            agree: true,
            agree_knltb: false,
            agree_email: false,
            email_confirmed: false
        }
    },

    getters: {
        all: state => state.entry,
        name: state => state.entry.name
    },

    mutations: {
        assign: ( state, payload ) =>
        {
            state.entry = Object.assign({}, state.entry, payload );
        }
    },

    actions: {
        auth: ( context, payload ) =>
        {
            context.commit( 'assign', payload );

            return new EntryProxy()
                .auth( context.getters.all )
                .then( response =>
                {
                    context.commit( 'assign', response );
                    return response;
                })
                .catch( error =>
                {
                    return Promise.reject( error );
                });
        },

        merge: ( context, payload ) =>
        {
            context.commit( 'assign', payload );
        },

        save: ( context, payload ) =>
        {
            context.commit( 'assign', payload );

            return new EntryProxy()
                .create( context.getters.all )
                .then( response =>
                {
                    context.commit( 'assign', response );
                    return response;
                })
                .catch( error =>
                {
                    return Promise.reject( error );
                });
        }
    }
};
