import { createRouter, createWebHistory } from 'vue-router';

import store from '@/app/store';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'landing',
            component: () =>
            {
                return import( /* webpackChunkName: "landing" */ '@/app/pages/Landing' );
            }
        },
        {
            path: '/register',
            name: 'register',
            component: () =>
            {
                return import( /* webpackChunkName: "landing" */ '@/app/pages/MatchView' );
            },
            beforeEnter( to, from, next )
            {
                next( '/' );
            }
        },
        {
            path: '/confirm',
            name: 'confirm',
            component: () =>
            {
                return import( /* webpackChunkName: "landing" */ '@/app/pages/ConfirmView' );
            },
            beforeEnter( to, from, next )
            {
                next( '/' );
            }
        },
        {
            path: '/thankyou',
            component: () =>
            {
                if( store.getters['entry/all'].email_confirmed )
                {
                    return import( /* webpackChunkName: "landing" */ '@/app/pages/EmailConfirmThankYouView' );
                }

                return import( /* webpackChunkName: "landing" */ '@/app/pages/ThankYouView' );
            }
        },

        {
            path: '/competition',
            name: 'competition',
            component: () =>
            {
                return import( /* webpackChunkName: "competition" */ '@/app/pages/CompetitionView' );
            }
        },

        {
            path: '/email-confirm',
            name: 'email-confirm',
            component: () =>
            {
                return import( /* webpackChunkName: "email-confirm" */ '@/app/pages/EmailConfirmThankYouView' );
            }
        }
    ]
});

router.beforeEach( ( to, from, next ) =>
{
    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default router;
